import Swiper from 'swiper';
import WOW from 'wowjs';
import {mobileMenu} from './mobileMenu'
import {briefCheckboxes} from "./briefCheckbox";
import {briefForm} from "./briefForm";
import ScrollReveal from 'scrollreveal'

ScrollReveal({ duration: 500, delay: 375 });
ScrollReveal().reveal('.project-list-item', { distance: '-60px', delay: '500' });

mobileMenu();

let mySwiper = new Swiper('.swiper-container', {
  // Optional parameters
  loop: true,
  pagination: {
    el: '.reviews-pagination-page',
    type: 'fraction',
  },
  navigation: {
    nextEl: '.slider-right',
    prevEl: '.slider-left',
  }
});

let myTabs = document.querySelectorAll(".tabs-item");

let myTabClicks = (tabClickEvent) => {

  let myTabs = document.querySelectorAll(".tabs-item");

  for (let i = 0; i < myTabs.length; i++) {
    myTabs[i].addEventListener("click", myTabClicks);
  }

  for (let i = 0; i < myTabs.length; i++) {
    myTabs[i].classList.remove("tabs-item_active");
  }

  let clickedTab = tabClickEvent.currentTarget;

  clickedTab.classList.add("tabs-item_active");

  let myContentPanes = document.querySelectorAll(".tab");

  for (let i = 0; i < myContentPanes.length; i++) {
    myContentPanes[i].classList.remove("tab_active");
  }

  let anchorReference = tabClickEvent.target;
  let activePaneId = anchorReference.getAttribute("data-id");
  let activePane = document.getElementsByClassName(activePaneId);

  for (let i = 0; i < activePane.length; i++) {
    activePane[i].classList.add("tab_active");
  }
};


for (let i = 0; i < myTabs.length; i++) {
  myTabs[i].addEventListener("click", myTabClicks);
}

let briefInput = document.querySelector('.breef-value');
let briefValues = [];
let serviceInputs = document.querySelectorAll('.breef-list-checkboxes__checkbox');

let briefValue = () => {
  briefInput.value = briefValues.join(',');
};

let removeValue = (value) => {
  for (let i = 0; i < briefValues.length; i++) {
    briefValues[i] === value ? briefValues.splice(i, 1) : false
  }
};


serviceInputs.forEach(checkbox => {
  checkbox.addEventListener('change', (e) => {
    checkbox.checked ? briefValues.push(checkbox.value) : removeValue(checkbox.value);
    briefValue()
  })
});


let form = document.querySelector('.form');
if (form) {
  let name = form.querySelector('input[name="name"]');
  let email = form.querySelector('input[name="email"]');
  let message = form.querySelector('textarea[name="description"]');
  let phone = form.querySelector('input[name="phone"]');
  let companyName = form.querySelector('input[name="company_name"]');
  let token = form.querySelector('input[name="csrfmiddlewaretoken"]');
  let serviceType = form.querySelector('input[name="type_product"]');
  let validationState;
  let allInputs = form.querySelectorAll('.form-input');
  let formAttribute = form.getAttribute('action');
  let checkboxValid = false;
  let checkboxes = form.querySelectorAll('input[type="checkbox"]');
  let checkboxesContainer = form.querySelector('.breef-list-container');
  let overlayModal = document.createElement('div');
  let modal = document.querySelectorAll('modal');



  overlayModal.className = "overlay overlay_active overlay_modal";

  if(phone){
    let phoneMask = new IMask( phone, {mask: '+{7} (000) 000-00-00' })
  }

  message ? autosize(message) : null;

  allInputs.forEach(item => {
    let input = item.querySelector('input');
    input.addEventListener('change', () => {
      if (input.classList.contains('text-info')) {
        if (input.value.match(/^[a-zA-Zа-яА-Я'][a-zA-Zа-яА-Я-' ]+[a-zA-Zа-яА-Я']?$/u)) {
          item.classList.add('ok');
          item.classList.remove('false');
        } else {
          item.classList.add('false');
          item.classList.remove('ok');
        }
      }
      if (input.classList.contains('phone-info')) {
        if (input.value.replace(/\D/g, '').substr(10, 10)) {
          item.classList.add('ok');
          item.classList.remove('false');
        } else {
          item.classList.add('false');
          item.classList.remove('ok');
        }
      }
      if (input.classList.contains('mail-info')) {
        if (input.value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
          item.classList.add('ok');
          item.classList.remove('false')
        } else {
          item.classList.add('false');
          item.classList.remove('ok')
        }
      }
      if (input.classList.contains('breef-value')) {
        if (input.value !== '') {
          item.classList.add('ok');
          item.classList.remove('false')
        } else {
          item.classList.add('false');
          item.classList.remove('ok')
        }
      }
      console.log(input.value);
      vallidationError(item);

      if (input.value === '') {
        let errorMessage = item.querySelector('.form-input-error');
        errorMessage ? errorMessage.remove() : null;
        item.classList.remove('ok');
        item.classList.remove('false');
      }
    })
  });

  let vallidationError = (elem) => {
    let error = document.createElement('div');
    error.classList.add('form-input-error');
    error.textContent = `Ошибка`;

    let errorMessage = elem.querySelector('.form-input-error');
    if (elem.value === '' || elem.classList.contains('false') || !elem.classList.contains('ok')) {
      !errorMessage ? elem.append(error) : null;
      elem.classList.add('false')
    } else {
      errorMessage ? errorMessage.remove() : null;
      elem.classList.remove('false')
    }
  };

  let validation = (elems, validationStateError = true) => {

    validationState = validationStateError;
    console.log(validationState);
    let typeProduct = document.querySelector('.type_product');
    if (typeProduct) {
      let typeProductInput = typeProduct.querySelector('input');
      if (typeProductInput.value === '') {
        typeProduct.classList.add('false');
        typeProduct.classList.remove('ok')
      } else {
        typeProduct.classList.add('ok');
        typeProduct.classList.remove('false')
      }
      checkboxError(checkboxValid, true)
    }
    elems.forEach(item => {
      let input = item.querySelector('input');
      if (item.classList.contains('require')) {
        if (input.value === '' || item.classList.contains('false') || !item.classList.contains('ok')) {
          validationStateError = false;
          vallidationError(item);
          validationState = validationStateError;
        } else {
        }
      }
      if (!item.classList.contains('require') && item.classList.contains('false')) {
        validationStateError = false;
        vallidationError(item);
        validationState = validationStateError;
      }
    })
  };

  let modalRemove = () => {
    let modalRemoveBtns = document.querySelectorAll('.modal-close-js');
    let overlayModal = document.querySelector('.overlay_modal');
    let modal = document.querySelector('.modal')
    modalRemoveBtns.forEach(btn => {
      btn.addEventListener('click', (e) => {
        e.preventDefault();
        overlayModal.remove();
      })
    });
    overlayModal.addEventListener('click',(e) => {
      e.preventDefault();
      overlayModal.remove();
    });
    modal.addEventListener('click', (e) => {
      e.stopPropagation();
    });
  };

  let success = () => {
    overlayModal.innerHTML = `<div class="modal modal-success"><a href="javascript:;" class="modal-close-js close">
            <img src="/static/assets/img/icons/close.svg" alt="">
        </a><div class="modal-content"><div class="h3">Заявка отправлена!</div><div class="modal-text">Мы свяжемся с вами в ближайшее время и обсудим
все подробности проекта</div><a  href="javascript:;" class="primaryBtn modal-close-js"><span>Отлично!</span></a></div></div>`;
    body.appendChild(overlayModal);
    modalRemove();
  };

  let fail = () => {
    overlayModal.innerHTML = `<div class="modal modal-success"><a href="javascript" class="modal-close-js close">
            <img src="/static/assets/img/icons/close.svg" alt="">
        </a><div class="modal-content"><div class="h3">Ошибка :(</div><div class="modal-text">Не удалось отправить заявку.
Вернитесь назад к форме и попробуйте отправить заново.</div><a href="javascript:;" class="primaryBtn modal-close-js"><span>Вернуться</span></a></div></div>`;
    body.appendChild(overlayModal);
    modalRemove();
  };


  form ? (form.addEventListener('submit', (e) => {
    e.preventDefault();

    debugger

    let formData = new FormData();

    console.log(serviceType.value);

    formData.append('name', name.value);
    formData.append('email', email.value);
    formData.append('message', message.value);
    formData.append('phone', phone.value);
    formData.append('type_product', serviceType.value);
    formData.append('company_name', companyName.value);
    token ? formData.append('csrfmiddlewaretoken', token.value) : null;

    validation(allInputs, true);

    console.log(validationState);

    validationState ? (
      fetch(formAttribute, {
        method: 'POST',
        body: formData
      })
        .then((response) => {
          response.status === 200 ? success() : fail()
        })
        .then((result) => {

        })
    ) : (
      console.log('error')
    )


  })) : (
    null
  );


  let checkboxError = (checkboxState = false, submit = false) => {

    let error = document.createElement('div');
    error.classList.add('form-checkboxes-error', 'form-input-error');
    error.textContent = `Ошибка`;

    let errorMessage = checkboxesContainer.querySelector('.form-checkboxes-error');

    if (!checkboxState) {
      if(submit){
        !errorMessage ? checkboxesContainer.append(error) : null;
      } else {
        return null
      }
    } else {
      errorMessage ? errorMessage.remove() : null;
    }
  };

  let checkboxValidation = () => {
    !serviceType.value ? checkboxValid = false : checkboxValid = true;
    checkboxes.forEach(checkbox => {
      checkbox.addEventListener('change', (e) => {
        let errorMessage = checkboxesContainer.querySelector('.form-checkboxes-error');
        console.log(checkboxValid);
        serviceType.value ? checkboxValid = true : checkboxValid = false;
        checkboxError(checkboxValid, false)
      })
    });
  };
  checkboxValidation();
}

//
// let mySwiper = new Swiper('.swiper-container', {
//   // Optional parameters
//   loop: true,
//   pagination: {
//     el: '.reviews-pagination-page',
//     type: 'fraction',
//   },
//   navigation: {
//     nextEl: '.slider-right',
//     prevEl: '.slider-left',
//   }
// });



new WOW.WOW().init();
