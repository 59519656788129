export let mobileMenu = () => {
  let body = document.querySelector('body');
  let menuState = false;
  let overlay = document.querySelector('.overlay');
  let mobileBtn = document.querySelector('.nav-item_mobile');
  let closeBtn = document.querySelector('.close');
  let menu = document.querySelector('.menu');

  let menuToggle = () => {
    if (!menuState) {
      overlay.classList.add('overlay_active')
      menuState = true;
      body.style.overflow = 'hidden';
    } else {
      overlay.classList.remove('overlay_active');
      menuState = false;
      body.style.overflow = '';
    }
  };

  if (menu !== null) {
    mobileBtn.addEventListener('click', (e) => {
      e.preventDefault();
      menuToggle();
    });

    closeBtn.addEventListener('click', (e) => {
      e.preventDefault();
      menuToggle();
    });

    overlay.addEventListener('click', (e) => {
      e.preventDefault();
      menuToggle();
    });

    menu.addEventListener('click', (e) => {
      e.stopPropagation()
    });

  }

};


